<template>
  <div>
    <div v-if="!loaded" class="d-flex justify-content-center mb-3">
      <ct-centered-spinner />
    </div>
    <div v-else>
      <b-card>
        <b-card-text>
          <div v-if="monitoringTrialEligible">
            <h3 class="mt-0">
              {{ `${product.name} - Free Trial (${product.trial_duration_days} Days)` }} &mdash; {{ product.price | dollars }}
            </h3>
            <p>After {{ product.trial_duration_days }} days, the price will be adjusted to {{ productPrice | dollars }} per month.</p>
          </div>
          <h3 v-else class="mt-0">
            {{ product.name }} &mdash; {{ product.price | dollars }}
          </h3>

          <div v-if="product.schema">
            <h3 class="text-center">
              Required information
            </h3>

            <schema-form
              ref="productData"
              v-model="productData"
              :fields="product.schema"
            />
          </div>
        </b-card-text>

        <b-button
          variant="primary"
          class="float-right mt-1"
          aria-label="add to cart button"
          @click="submitted(productData)"
        >
          Add to Cart
        </b-button>
      </b-card>
    </div>
  </div>
</template>

<script>
import { dollars } from '@/filters'
import { mapActions, mapGetters } from 'vuex'
import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner'

export default {
  name: 'MonitoringService',

  filters: {
    dollars,
  },

  components: {
    CtCenteredSpinner,
    SchemaForm: () => import('@/components/SchemaForm'),
  },

  props: {
    product: Object,
    company: Object,
  },

  data() {
    return {
      productData: {},
      loaded: false,
      productPrice: 0,
    }
  },

  computed: {
    ...mapGetters('monitoring', ['monitoringTrialEligible']),
    ...mapGetters('companies', {
      company: 'currentCompany',
    }),
  },

  async mounted () {
    this.loaded = false
    await this.determineTrialEligibilityMonitoring(this.company.id)
    this.isTrial()
    this.loaded = true
  },

  methods: {
    ...mapActions('checkout', ['addToCart']),
    ...mapActions('monitoring', ['determineTrialEligibilityMonitoring']),
    async submitted(productData) {
      if(this.validate(productData)) {
        await this.addToCart({ ...this.product, data: productData, isTrial: this.monitoringTrialEligible })
        this.$emit('completed')
      }
    },
    validate() {
      if (!this.$refs.productData) {
        return true
      }

      return this.$refs.productData.form.validate()
    },
    isTrial(){
      // cache actual product price
      this.productPrice = this.product.price
      if (this.monitoringTrialEligible){
        this.product.price = 0
      } else {
        this.productData.bypass_trial = true
      }
    },
  },
}
</script>
